<template>
  <div class="d-flex justify-content-between align-items-center">
    <b-media no-body>
      <b-media-aside class=" mr-75 align-self-start">
        <store-image
          image-class="badge-secondary rounded"
          style="width: 42px; height: 42px;"
          :store-id="user.image_id"
          avatar
        />
      </b-media-aside>
      <b-media-body class="my-auto">
        <h6 class="mb-0">
          <span v-if="teacher">{{ `${teacher.surname} ${teacher.name} ${teacher.patronymic}` }}</span>
          <span v-else>{{ user.username }}</span>
        </h6>
        <small>+{{ user.phone }}</small>
      </b-media-body>
    </b-media>
    <b-button
      class="btn-icon"
      variant="primary"
      :to="{name: 'cabinet.stream'}"
    >
      <feather-icon icon="PlayIcon" />
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'

export default {
  name: 'ListTeacher',
  components: {
    StoreImage,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    teacher() {
      return this.user.usersTeachers
    },
  },
}
</script>

<style scoped>

</style>
