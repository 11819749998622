<template>
  <div>
    <h2 class="mb-2">
      Результаты последних упражнений
    </h2>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="teachersTable"
        striped
        class="rounded-top"
        :items="sessions"
        responsive
        :fields="tableFields"
        primary-key="id"
        show-empty
        :empty-text="$t('page.students.search.labels.no-items')"
        :sort-by.sync="filter.sortBy"
        :sort-desc.sync="filter.sortDesc"
      >
        <template #cell(user_name)="row">
          <div class="d-flex align-items-center">
            <store-image
              class="mr-50"
              :store-id="row.item.user.image_id"
              avatar
              size="20"
            />
            <span>{{ row.value }}</span>
          </div>
        </template>
        <template #cell(result)="row">
          <span
            class="text-success"
            :title="$t('page.exercises_list.table.labels.correct_answers')"
          >
            {{ row.item.correct_number }}
          </span>
          <span>/</span>
          <span
            class="text-danger"
            :title="$t('page.exercises_list.table.labels.incorrect_answers')"
          >
            {{ row.item.incorrect_number }}
          </span>
        </template>
        <template #cell(correct_percentage)="row">
          <b-progress
            show-progress
            :value="row.value"
            max="100"
            :variant="percentageColor(row.value)"
            striped
          />
        </template>
        <template #cell(actions)="row">
          <div class="d-flex flex-row justify-content-end align-items-center">
            <b-button
              variant="flat-primary"
              class="btn-icon rounded-circle"
              size="sm"
              @click="row.toggleDetails"
            >
              <feather-icon
                size="15"
                :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              />
            </b-button>
            <b-dropdown
              size="sm"
              variant="flat-secondary"
              toggle-class="text-decoration-none btn-icon rounded-circle ml-1"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  size="15"
                  icon="MoreVerticalIcon"
                />
              </template>
              <b-dropdown-item @click="row.toggleDetails">
                Подробнее
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="deleteSessionConfirm(row.item.id)">
                <span class="text-danger">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #row-details="row">
          <b-card>
            {{ row.item.user }}
          </b-card>
        </template>
      </b-table>
      <table-footer
        :pagination-updated="getSessions"
        :pagination="pagination"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BProgress,
  BCard, BTable, BButton, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ConfirmToast from '@core/components/toastification/ConfirmToast.vue'
import { diffBetweenDates, getUserFullName } from '@/helpers'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import StoreImage from '@/components/store/StoreImage.vue'

export default {
  name: 'ExercisesList',
  components: {
    StoreImage,
    TableFooter,
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BDropdownDivider,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    sessions: [],
    pagination: {
      perPage: 5,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    filter: {
      search: '',
      sortBy: 'created',
      sortDesc: true,
    },
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'id',
          label: this.$t('page.exercises_list.table.labels.id'),
          sortable: true,
        },
        {
          key: 'started_at',
          label: this.$t('page.exercises_list.table.labels.created_at'),
          sortable: true,
          formatter: (value, key, exercise) => (exercise.started_at ? this.$options.filters.dateTime(exercise.started_at) : this.$t('no_data')),
        },
        {
          key: 'user_name',
          label: this.$t('page.exercises_list.table.labels.user_name'),
          sortable: false,
          formatter: (value, key, exercise) => getUserFullName(exercise.user),
        },
        {
          key: 'exercises.name',
          label: this.$t('page.exercises_list.table.labels.name'),
          sortable: true,
          formatter: (value, key, exercise) => (exercise.exercises ? exercise.exercises.name : this.$t('no_data')),
        },
        {
          key: 'duration',
          label: this.$t('page.exercises_list.table.labels.duration'),
          sortable: false,
          formatter: (value, key, exercise) => (exercise.finished_at ? diffBetweenDates(exercise.started_at, exercise.finished_at) : this.$t('no_data')),
        },
        // {
        //   key: 'result',
        //   label: this.$t('page.exercises_list.table.labels.result'),
        //   sortable: false,
        // },
        {
          key: 'correct_percentage',
          label: this.$t('page.exercises_list.table.labels.percentage'),
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    sortBy() {
      this.getSessions()
    },
    users() {
      this.getSessions()
    },
  },
  async created() {
    await this.getSessions()
  },
  methods: {
    async getSessions() {
      const { items, _meta } = await this.$http.get('/lesson-session-exercises/index', {
        params: {
          sort: this.sortBy,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          user_id: this.users.join(','),
          expand: 'user,exercises',
        },
      })
      this.pagination.totalCount = _meta.totalCount
      this.sessions = items
    },
    async deleteSessionConfirm(id) {
      this.$toast({
        component: ConfirmToast,
        props: {
          title: this.$t('page.sessions_list.table.labels.delete_confirm'),
          onConfirm: async () => {
            await this.$http.delete(`/lesson-session-exercises/delete?id=${id}`)
            this.getSessions()
          },
        },
      })
    },
    percentageColor(value) {
      const val = parseInt(value, 10)
      if (val === 0) return 'danger'
      if (val <= 75) return 'warning'
      return 'success'
    },
  },
}
</script>

<style scoped>

</style>
