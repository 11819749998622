<template>
  <div>
    <b-card
      v-if="user"
      no-body
      class="card-developer-meetup"
    >
      <div class="bg-light-primary rounded-top text-center">
        <img
          :src="require('@/assets/images/pages/pricing-Illustration.svg')"
          alt="Meeting Pic"
          height="236"
          class=""
        >
      </div>
      <b-card-body>
        <div class="meetup-header d-flex align-items-center">
          <!--          <div-->
          <!--            v-if="false"-->
          <!--            class="meetup-day"-->
          <!--          >-->
          <!--            <h6 class="mb-0">-->
          <!--              THU-->
          <!--            </h6>-->
          <!--            <h3 class="mb-0">-->
          <!--              24-->
          <!--            </h3>-->
          <!--          </div>-->
          <div class="my-auto">
            <h4 class="card-title mb-25">
              <span v-if="isTeacher">Занятие с учеником</span>
              <span v-else-if="isStreamActive">Учитель начал урок</span>
              <span v-else>Занятие с учителем</span>
            </h4>
            <p
              v-if="isStreamActive"
              class="card-text mb-0"
            >
              <span>Урок идет</span>
            </p>
            <p
              v-else
              class="card-text mb-0"
            >
              <span v-if="isTeacher">Начните урок</span>
              <span v-else>Урок еще не начался</span>
            </p>
          </div>
        </div>
        <!--        <div-->
        <!--          v-if="false"-->
        <!--          class="media"-->
        <!--        >-->
        <!--          <div class="media-aside mr-1 align-self-start">-->
        <!--            <span-->
        <!--              class="b-avatar badge-light-primary rounded"-->
        <!--              style="width: 34px; height: 34px;"-->
        <!--            >-->
        <!--              <feather-icon-->
        <!--                icon="CalendarIcon"-->
        <!--              />-->
        <!--            </span>-->
        <!--          </div>-->
        <!--          <div class="media-body">-->
        <!--            <h6 class="mb-0">-->
        <!--              Скоро-->
        <!--            </h6>-->
        <!--            <small>Скоро</small>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="dashboard-lesson-buttons mt-2 pt-50">
          <b-button
            class="start-lesson-button"
            variant="primary"
            :to="{name: 'cabinet.stream'}"
            :disabled="isTeacher && isStreamActive"
          >
            <feather-icon
              icon="PlayIcon"
              class="mr-50"
            />
            <span
              v-if="isTeacher"
              class="align-middle"
            >
              Начать
            </span>
            <span
              v-else
              class="align-middle"
            >
              Присоединиться
            </span>
          </b-button>
          <b-button
            class="ml-lg-1 demo-lesson-button"
            variant="outline-primary"
            @click="startTestStream"
          >
            <feather-icon
              icon="VideoIcon"
              class="mr-50"
            />
            Демо
          </b-button>
          <device-settings-modal>
            <template #activator="{show}">
              <b-button
                variant="outline-primary"
                class="btn-icon ml-1 settings-button"
                style="height: 40px"
                :disabled="isTeacher && isStreamActive"
                @click="show"
              >
                <feather-icon
                  icon="SettingsIcon"
                  size="19"
                  class="d-block"
                />
              </b-button>
            </template>
          </device-settings-modal>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCardBody, BCard, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { v4 } from 'uuid'
import DeviceSettingsModal from '@/components/video-stream/DeviceSettingsModal.vue'
import { teacherRole, userRoles } from '@/store/user'
// import StoreImage from '@/components/store/StoreImage.vue'

export default {
  name: 'UpcomingStream',
  components: {
    // StoreImage,
    DeviceSettingsModal,
    BCardBody,
    BCard,
    BButton,
  },
  data() {
    return {
      isStreamActive: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
  async created() {
    if (this.isTeacher) {
      const { items } = await this.$http.get(`/sessions/index?sort=-id&teacher_id=${this.user.id}`)
      if (items.length && items[0].status === 0) this.isStreamActive = true
    } else {
      const { items } = await this.$http.get('/room/current-students-find?sort=-id')
      if (items.length) this.isStreamActive = true
    }
  },
  methods: {
    startTestStream() {
      this.$router.push({ name: 'cabinet.room', params: { room_id: v4() }, query: { origin: 'cabinet.dashboard' } })
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';

.dashboard-lesson-buttons {
  display: grid;
  grid-template-columns: 1fr auto auto;
}

@media (max-width: 830px) {
  .start-lesson-button {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .demo-lesson-button {
    margin-top: 1rem;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .settings-button {
    margin-top: 1rem;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}
</style>
