<template>
  <b-card
    no-body
    class="card-employee-task"
    style="height: 400px"
  >
    <b-card-header>
      <div class="d-flex flex-fill justify-content-between align-items-center">
        <b-card-title>
          <span v-if="isTeacher">Ваши ученики</span>
          <span v-else>Ваши учителя</span>
        </b-card-title>
        <b-link
          v-if="isTeacher && selectedStudents.length"
          @click="selectedStudents = []"
        >
          Все
        </b-link>
      </div>
      <div v-if="isStudent">
        <device-settings-modal>
          <template #activator="{show}">
            <b-link
              size="sm"
              @click="show"
            >
              <feather-icon
                class="mr-50"
                icon="SettingsIcon"
              />
              Видео и звук
            </b-link>
          </template>
        </device-settings-modal>
      </div>
    </b-card-header>
    <b-card-body
      class="nice-scrollbar"
      style="max-height: 100%; overflow-y: auto"
    >
      <div v-if="loading">
        <div
          v-for="skel in 4"
          :key="`skel-${skel}`"
          class="d-flex align-items-center mb-1"
        >
          <skeletor
            width="30px"
            height="30px"
            circle
            class="mr-1"
          />
          <div class="flex-fill">
            <skeletor
              width="85%"
              height="16px"
              class="mb-50"
            />
            <skeletor
              width="65%"
              height="12px"
              class="mb-50"
            />
          </div>
          <skeletor
            width="25px"
            height="25px"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="isTeacher">
          <student
            v-for="student in students"
            :key="student.id"
            :user="student"
            :selected="isStudentSelected(student)"
            @selected="studentSelected(student)"
          />
        </div>
        <div v-else>
          <list-teacher
            v-for="teacher in teachers"
            :key="teacher.id"
            :user="teacher"
          />
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BLink,
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Student from '@/components/dashboard/user/ListStudent.vue'
import { studentRole, teacherRole, userRoles } from '@/store/user'
import ListTeacher from '@/components/dashboard/user/ListTeacher.vue'
import DeviceSettingsModal from '@/components/video-stream/DeviceSettingsModal.vue'

export default {
  name: 'StudentsList',
  components: {
    DeviceSettingsModal,
    ListTeacher,
    Student,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BLink,
  },
  data: () => ({
    students: [],
    teachers: [],
    selectedStudents: [],
    loading: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    isStudentSelected(student) {
      return this.selectedStudents.includes(student.id)
    },
    studentSelected(student) {
      const selectedIndex = this.selectedStudents.indexOf(student.id)
      if (selectedIndex >= 0) {
        this.selectedStudents.splice(selectedIndex, 1)
      } else {
        this.selectedStudents = [student.id]
      }
      this.$emit('studentSelected', this.selectedStudents)
    },
    async getList() {
      this.loading = true
      if (this.isTeacher) {
        await this.getStudents()
      } else if (this.isStudent) {
        await this.getTeachers()
      }
      this.loading = false
    },
    async getStudents() {
      const { items, _meta } = await this.$http.get('/user/current-teacher-student-index?limit=50&page=1&expand=usersStudent')
      this.meta = items.length ? _meta : false
      this.students = items
    },
    async getTeachers() {
      const { items, _meta } = await this.$http.get('/user/current-student-teacher-index?limit=50&page=1&expand=usersTeachers')
      this.meta = items.length ? _meta : false
      this.teachers = items
    },
  },
}
</script>

<style scoped>

</style>
