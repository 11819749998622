<template>
  <b-card
    no-body
    class="mb-0"
    :bg-variant="selected ? 'primary' : ''"
    @click="$emit('selected')"
  >
    <div class="p-50 employee-task d-flex justify-content-between align-items-center cursor-pointer">
      <b-media no-body>
        <b-media-aside class=" mr-75 align-self-center">
          <store-image
            image-class="badge-secondary rounded"
            style="width: 42px; height: 42px;"
            :store-id="user.image_id"
            avatar
            wrapper-class="d-flex align-items-center"
          />
        </b-media-aside>
        <b-media-body class="my-auto">
          <h6 class="mb-0">
            <span v-if="student">{{ `${student.surname} ${student.name} ${student.patronymic}` }}</span>
            <span v-else>{{ user.username }}</span>
            <small
              v-if="student"
              class="text-secondary"
            >, {{ student.age }}</small>
          </h6>
          <small>{{ `${user.email}, ${user.phone}` }}</small>
        </b-media-body>
      </b-media>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'

export default {
  name: 'ListStudent',
  components: {
    StoreImage,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCard,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    student() {
      return this.user.userStudent
    },
  },
}
</script>

<style scoped>

</style>
