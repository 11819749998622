<template>
  <div>
    <b-card
      no-body
      style="height: 400px"
    >
      <b-card-header>
        <b-card-title>
          <span>Домашнее задание</span>
        </b-card-title>
        <b-link :to="{name: 'cabinet.hometasks'}">
          Все
        </b-link>
      </b-card-header>
      <b-card-body
        class="nice-scrollbar"
        style="max-height: 100%; overflow-y: auto"
      >
        <b-alert
          v-if="!tasks.length"
          show
          variant="secondary"
        >
          <div class="alert-body">
            Пока нет заданий
          </div>
        </b-alert>
        <router-link
          v-for="task in tasks"
          :key="`hometask-${task.id}`"
          :to="{name: 'cabinet.hometasks.task', params: {task_id: task.id}}"
        >
          <b-card
            no-body
            bg-variant="primary"
          >
            <b-card-body>
              <div class="text-white d-flex align-items-start justify-content-between">
                <div>
                  <p class="font-medium-3 font-weight-bolder mb-50">
                    №{{ task.order }}
                  </p>
                  <p class="mb-0">
                    {{ task.name }}
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </router-link>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCardBody, BCard, BCardTitle, BCardHeader, BLink, BAlert,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { HOMETASK_STATUS_ACTIVE } from '../../shared/constants'

export default {
  name: 'Hometask',
  components: {
    BCardBody,
    BCard,
    BCardTitle,
    BCardHeader,
    BLink,
    BAlert,
  },
  data() {
    return {
      tasks: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  async created() {
    const { items } = await this.$http.get('/home-tasks', {
      params: {
        status: HOMETASK_STATUS_ACTIVE,
        sort: '-id',
        student_id: this.user.id,
      },
    })
    this.tasks = items
  },
}
</script>

<style scoped>

</style>
