<template>
  <div>
    <b-card class="card-congratulation-medal cursor-pointer">
      <h5>Статистика уроков</h5>
      <h2 class="mt-1">
        <b-link v-if="sessionsInfo.total">
          {{ sessionsInfo.finished }} из {{ sessionsInfo.total }}
        </b-link>
        <b-link v-else>
          Не было уроков
        </b-link>
      </h2>
      <div class="d-flex align-items-center">
        <p class="mb-0 mr-1">
          Переносов: <span class="text-warning">{{ sessionsInfo.rescheduled || 'Нет' }}</span>
        </p>
        <p class="mb-0">
          Отмен занятий: <span class="text-warning">{{ sessionsInfo.canceled || 'Нет' }}</span>
        </p>
      </div>
      <b-progress
        class="w-75 mt-1"
        :max="sessionsInfo.total"
      >
        <b-progress-bar
          variant="danger"
          :value="sessionsInfo.canceled"
        />
        <b-progress-bar
          variant="primary"
          :value="sessionsInfo.finished"
        />
      </b-progress>

      <b-img
        :src="require('@/assets/images/illustration/badge.svg')"
        class="congratulation-medal"
        alt="Medal Pic"
      />
    </b-card>

    <statistic-card-horizontal
      class="cursor-pointer"
      icon="BookOpenIcon"
      color="success"
      statistic="0"
      statistic-title="Пройденных упражнений"
    />

    <statistic-card-horizontal
      class="cursor-pointer"
      icon="HomeIcon"
      color="info"
      statistic="0"
      statistic-title="Выполненных дом. заданий"
    />
  </div>
</template>

<script>
import {
  BCard, BLink, BImg, BProgress, BProgressBar,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { mapGetters } from 'vuex'
import { SESSION_STATUS_CANCELED, SESSION_STATUS_PASSED, SESSION_STATUS_RESCHEDULED } from '../../shared/constants'
import { studentRole, teacherRole, userRoles } from '../../store/user'

export default {
  name: 'SummaryStats',
  components: {
    StatisticCardHorizontal,
    BCard,
    BLink,
    BImg,
    BProgress,
    BProgressBar,
  },
  data: () => ({
    sessionsInfo: {
      total: 0,
      finished: 0,
      rescheduled: 0,
      canceled: 0,
    },
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
  },
  created() {
    this.getSessions()
  },
  methods: {
    async getSessions() {
      const url = this.isTeacher ? '/sessions' : '/sessions/current-student-index'
      const { items } = await this.$http.get(url, {
        params: {
          limit: 50,
          page: 1,
          teacher_id: this.isTeacher ? this.user.id : null,
        },
      })
      this.sessionsInfo = {
        total: items.length,
        finished: items.filter(session => session.status === SESSION_STATUS_PASSED).length,
        rescheduled: items.filter(session => session.status === SESSION_STATUS_RESCHEDULED).length,
        canceled: items.filter(session => session.status === SESSION_STATUS_CANCELED).length,
      }
    },
  },
}
</script>

<style scoped>

</style>
