<template>
  <div
    v-if="user && !isAdmin"
    class="pb-2"
  >
    <b-row>
      <b-col
        cols="12"
        md="6"
        xl="4"
      >
        <upcoming-stream v-if="isTeacher" />
        <hometask v-else />
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="4"
      >
        <users-list @studentSelected="students => selectedStudents = students" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="4"
      >
        <summary-stats />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <sessions-list class="mb-2" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <exercises-list :users="selectedStudents" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import UpcomingStream from '@/components/dashboard/UpcomingStream.vue'
import UsersList from '@/components/dashboard/UsersList.vue'
import SummaryStats from '@/components/dashboard/SummaryStats.vue'
import { teacherRole, userRoles, adminRole } from '@/store/user'
import SessionsList from '@/components/dashboard/SessionsList.vue'
import ExercisesList from '@/components/dashboard/ExercisesList.vue'
import Hometask from '@/components/dashboard/Hometask.vue'

export default {
  components: {
    Hometask,
    ExercisesList,
    SessionsList,
    SummaryStats,
    UsersList,
    UpcomingStream,
    BRow,
    BCol,
  },
  data: () => ({
    selectedStudents: [],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
  },
  watch: {
    selectedStudents() {

    },
  },
}
</script>

<style>

</style>
