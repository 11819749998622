<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      id="device-settings-modal"
      ref="modal"
      v-model="dialog"
      size="lg"
      centered
      :title="$t('page.video-stream.settings.title')"
      no-close-on-backdrop
    >
      <device-settings
        ref="deviceSettings"
        modal
      />
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <b-button
            variant="flat-secondary"
            class="mr-1"
            @click="closeDialog()"
          >
            {{ $t('components.button.labels.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            @click="saveAndClose()"
          >
            {{ $t('components.button.labels.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import DeviceSettings from '@/components/settings/video-stream/DeviceSettings.vue'

export default {
  name: 'DeviceSettingsModal',
  components: {
    DeviceSettings,
    BModal,
    BButton,
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    dialog(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.$refs.deviceSettings.startCheckStream()
        }, 500)
      }
    },
  },
  beforeDestroy() {
    this.closeDialog()
  },
  mounted() {
  },
  methods: {
    closeDialog() {
      if (this.$refs.deviceSettings) {
        this.$refs.deviceSettings.stopCheckStream()
        this.$refs.deviceSettings.stopSpeakersTest()
      }
      this.dialog = false
    },
    saveAndClose() {
      this.$refs.deviceSettings.saveSettings()
      this.closeDialog()
    },
    show() {
      this.dialog = !this.dialog
    },
  },
}
</script>

<style scoped>

</style>
